/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
// import { FontAwesomeIcon } from '@fortawesome/fontawesome-free'
import Swal from "sweetalert2";
import {
  Badge,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Col,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  UncontrolledTooltip,
  Dropdown,
} from "reactstrap";
import { BiDotsVertical } from "react-icons/bi";
// core components
import Header from "components/Headers/Header.js";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { FcDataBackup } from "react-icons/fc";

// core components

const BlogIndex = () => {
  //fetching api data

  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  var arrayForDeletion = [];
  const [data, setData] = useState([]);
  const [h1b, setH1B] = useState(data);
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedEndDate, setSelectedEndDate] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);



  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    let startDate = inputDate ? new Date(inputDate) : null;
    setSelectedDate(startDate);
    if (!startDate) {
      return true;
    }
    startDate = startDate.getTime()
    const filtered = data.filter((item) => {
      let createdDate = new Date(item.createdAt).toISOString().split('T')[0];
      createdDate = new Date(createdDate).getTime()
      if (selectedEndDate) {
        let endDate = new Date(selectedEndDate).getTime();
        return createdDate <= endDate && createdDate >= startDate;
      } else
        return createdDate >= startDate;
    });
    setH1B(filtered);
  };

  const handleEndDateChange = (e) => {
    const inputDate = e.target.value;
    let endDate = inputDate ? new Date(inputDate) : null;
    setSelectedEndDate(endDate);
    endDate = endDate.getTime()
    const filtered = data.filter((item) => {
      let createdDate = new Date(item.createdAt).toISOString().split('T')[0];
      createdDate = new Date(createdDate).getTime()
      if (!endDate) {
        return true;
      }
      if (selectedDate) {
        const startDate = new Date(selectedDate).getTime();
        return createdDate <= endDate && createdDate >= startDate;
      } else
        return createdDate <= endDate;
    });
    setH1B(filtered);
  };

  const [newContact, setNewContact] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    file: "",
  });

  const fetchH1B = () => {
    axios
      .get("https://backend.tror.ai/api/getAllH1B")
      .then((res) => {
        setData(res.data.result.slice(0, -306));
        setH1B(res.data.result.slice(0, -306));
      })
      .catch((err) => {
      });
  };

  const myH1B = h1b.map((h1b) => {
    return {
      firstName: h1b.firstName || h1b.name,
      middleName: h1b.middleName,
      lastName: h1b.lastName,
      email: h1b.email,
      phone: h1b.phoneNumber,
      birthday: h1b.birthday,
      countryBirth: h1b.countryBirth || h1b.country,
      passport: h1b.passport,
      masters: h1b.masters,
      visaStatus: h1b.visaStatus,
      NRE: h1b.NRE,
      technology: h1b.technology,
      country: h1b.country,
      state: h1b.state,
      city: h1b.city,
      mClgName: h1b.mClgName,
      mYop: h1b.mYop,
      mStream: h1b.mStream,
      bClgName: h1b.bClgName,
      bYop: h1b.bYop,
      bStream: h1b.bStream,
      expDate: h1b.expDate,
      message: h1b.message,
      referredBy: h1b.referredBy,
      status: h1b.status,
      file: h1b.file,
      passportFront: h1b.passportFront,
      passportBack: h1b.passportBack,
      date: h1b.createdAt && new Date(h1b.createdAt).toISOString().split('T')[0]
    };
  });

  const noDataToDelete = () => {
    Swal.fire({
      title: "No data to delete",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  };

  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Middle Name", key: "middleName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone" },
    { label: "Date of Birth", key: "birthday" },
    { label: "Country(Birth)", key: "countryBirth" },
    { label: "Passport number", key: "passport" },
    { label: "Masters in US", key: "masters" },
    { label: "Current Visa Status", key: "visaStatus" },
    { label: "Total No. of Years", key: "NRE" },
    { label: "Technology/Stream", key: "technology" },
    { label: "Country", key: "country" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Master - University Name", key: "mClgName" },
    { label: "Master - Year of Passing", key: "mYop" },
    { label: "Master - Stream", key: "mStream" },
    { label: "Bachelor - University Name", key: "bClgName" },
    { label: "Bachelor - Year of Passing", key: "bYop" },
    { label: "Bachelor - Stream", key: "bStream" },
    { label: "I94 Expiration date", key: "expDate" },
    { label: "Comment", key: "message" },
    { label: "Referred By", key: "referredBy" },
    { label: "Status", key: "status" },
    { label: "Resume", key: "file" },
    { label: "Passport Front", key: "passportFront" },
    { label: "Passport Back", key: "passportBack" },
    { label: "Date", key: "date" },
  ];

  const csvReport = {
    filename: "H1BApplications.csv",
    headers: headers,
    data: myH1B,
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`https://backend.tror.ai/api/deleteH1B/${id}`)
          .then((res) => {
            fetchH1B();
            Swal.fire("Deleted!", "H1B has been deleted.", "success");
          })
          .catch((err) => {
          });
      }
    });
  };

  const deleteAll = () => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios
          .delete("https://backend.tror.ai/api/deleteAllH1B")
          .then((res) => {
            fetchH1B();
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
            });
          })
          .catch((err) => {
          });
      }
    });
  };

  const [file_upload, setFile_upload] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", newContact.name);
    formData.append("email", newContact.email);
    formData.append("phoneNumber", newContact.phoneNumber);
    formData.append("message", newContact.message);
    formData.append("file", file_upload);

    axios
      .post("https://backend.tror.ai/api/h1b", formData)
      .then((res) => {
        Swal.fire({
          title: "Success",
          type: "success",
          text: "H1B Data has been added successfully",
        }).then((result) => {
          if (result.value) {
            fetchH1B();
            setNewContact({
              name: "",
              email: "",
              phoneNumber: "",
              message: "",
              file: "",
            });

            document.getElementById("File").value = "";
          }
        });
      })
      .catch((err) => {
      });
  };

  const H1Bstatus = (id, status) => {

    axios.put(`https://backend.tror.ai/api/updatestatus/${id}`, { status: status })
      .then((res) => {
        fetchH1B();
      })
      .catch((err) => {
      });

  }

  useEffect(() => {
    fetchH1B();
  }, []);
  return (
    <>
      <Header />
      {toggle === false ? (
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="4">
                      <h3 className="mb-0">H1B Application</h3>
                    </Col>
                    {/*Search bar*/}
                    <Col className="text-center" xs="3">
                      <Input
                        type="text"
                        placeholder="Search..."
                        style={{
                          height: "28px",
                          width: "300px",
                          position: "relative",
                          right: "35%",
                        }}
                        onChange={(e) => {
                          const search = e.target.value;

                          const newData = data.filter((h1b) => {
                            return (
                              h1b.name && h1b.name
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              h1b.firstName && h1b.firstName
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              h1b.technology
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              h1b.email
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              h1b.phoneNumber
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              h1b.message
                                .toLowerCase()
                                .includes(search.toLowerCase()) ||
                              h1b.status
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) && (!selectedDate || new Date(h1b.createdAt) > new Date(selectedDate)) &&
                              (!selectedEndDate || new Date(h1b.createdAt) <= new Date(selectedEndDate))
                          });
                          setH1B(newData);

                        }}
                      />

                    </Col>

                    <Col className="text-center" xs="2">
                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle style={{ height:"28px", padding:"2px 20px", fontSize:"11px"}}>
                          Filter By Date
                        </DropdownToggle>
                        <DropdownMenu className="p-3" style={{ minWidth: "250px" }}>
                          <div className="mb-2">
                            <label>Start Date:</label>
                            <Input
                              type="date"
                              value={
                                selectedDate instanceof Date && !isNaN(selectedDate)
                                  ? selectedDate.toISOString().split("T")[0]
                                  : ""
                              }
                              onChange={handleDateChange}
                              style={{ height: "28px" }}
                            />
                          </div>
                          <div>
                            <label>End Date:</label>
                            <Input
                              type="date"
                              value={
                                selectedEndDate instanceof Date && !isNaN(selectedEndDate)
                                  ? selectedEndDate.toISOString().split("T")[0]
                                  : ""
                              }
                              onChange={handleEndDateChange}
                              style={{ height: "28px" }}
                            />
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>

                    <Col className="text-right" xs="3">

                      <Button
                        href="#pablo"
                        id="deleteCheckedItem"
                        style={{
                          backgroundColor: "#ff0000",
                          color: "#fff",
                        }}
                        size="sm"
                        onClick={() => {
                          // fetch(
                          //   "https://backend.tror.ai/api/deleteMultipleContacts",
                          //   {
                          //     method: "DELETE",
                          //     headers: {
                          //       "Content-Type": "application/json",
                          //     },
                          //     body: JSON.stringify({
                          //       contactIds: arrayForDeletion,
                          //     }),
                          //   }
                          // ).then((res) => {
                          Swal.fire({
                            title: `${arrayForDeletion.length === 0 ? "No item selected" : "Are you sure?"}`,
                            text: `${arrayForDeletion.length === 0 ? "Please select any one item before deleting" : "You won't be able to revert this!"}`,
                            type: "warning",
                            showCancelButton: true,
                          }).then((result) => {
                            if (result.value) {
                              fetch(
                                "https://backend.tror.ai/api/deleteMultipleH1B",
                                {
                                  method: "DELETE",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    contactIds: arrayForDeletion,
                                  }),
                                }
                              )
                              fetchH1B();
                            }
                            document
                              .querySelectorAll("input[type=checkbox]")
                              .forEach((element) => {
                                element.checked = false;
                              });

                            arrayForDeletion = [];
                            fetchH1B();
                          });
                          // });
                        }}
                      >
                        Delete Checked Row
                      </Button>
                      <Button
                        href="#pablo"
                        style={{
                          backgroundColor: "green",
                        }}
                        size="sm"
                      >
                        <CSVLink
                          {...csvReport}
                          style={{
                            color: "white",
                          }}
                        >
                          Download CSV
                        </CSVLink>
                      </Button>
                      {/* <Button
                        color="primary"
                        onClick={(e) => {
                          handleToggle(e);
                        }}
                        size="sm"
                      >
                        Add New
                      </Button> */}
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">First Name</th>
                      <th scope="col">Middle Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Date of Birth</th>
                      <th scope="col">Country(Birth)</th>
                      <th scope="col">Passport number</th>
                      <th scope="col">Masters in US</th>
                      <th scope="col">Current Visa Status</th>
                      <th scope="col">Total no. of years of experience</th>
                      <th scope="col" style={{ width: "200px !important" }}>Technology/Stream</th>
                      <th scope="col">Country</th>
                      <th scope="col">State</th>
                      <th scope="col">City</th>
                      <th scope="col">Master - University Name</th>
                      <th scope="col">Master - Year of Passing</th>
                      <th scope="col">Master - Stream</th>
                      <th scope="col">Bachelor - University Name</th>
                      <th scope="col">Bachelor - Year of Passing</th>
                      <th scope="col">Bachelor - Stream</th>
                      <th scope="col">I94 Expiration date</th>
                      <th scope="col">Comment</th>
                      <th scope="col">How did you reached us?</th>
                      <th scope="col">Status</th>
                      <th scope="col">Resume</th>
                      <th scope="col">Passport Front</th>
                      <th scope="col">Passport Back</th>
                      <th scope="col">Date</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  {h1b.length > 0 ? (
                    <tbody>
                      {h1b.map((h1b) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              // className="custom-control-input"
                              style={{
                                width: "px",
                                height: "20px",
                              }}
                              id="CheckAllZ"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // "background-color: red, color: #fff, position: relative, left: 60%";
                                  arrayForDeletion.push(h1b._id);
                                } else {
                                  arrayForDeletion.splice(
                                    arrayForDeletion.indexOf(h1b._id),
                                    1
                                  );
                                }
                              }}
                            />
                          </td>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {h1b.firstName || h1b.name}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          {/*Article Category*/}

                          <td>{h1b.middleName}</td>
                          <td>{h1b.lastName}</td>
                          <td>{h1b.email}</td>
                          <td>{h1b.phoneNumber}</td>
                          <td>{h1b.birthday}</td>
                          <td>{h1b.countryBirth || h1b.country}</td>
                          <td>{h1b.passport}</td>
                          <td>{h1b.masters}</td>
                          <td>{h1b.visaStatus}</td>
                          <td>{h1b.NRE}</td>
                          <td> <div style={{ maxWidth: '300px', wordWrap: 'break-word', overflow: 'auto' }}>{h1b.technology}</div> </td>
                          <td>{h1b.country}</td>
                          <td>{h1b.state}</td>
                          <td>{h1b.city}</td>
                          <td>{h1b.mClgName}</td>
                          <td>{h1b.mYop}</td>
                          <td>{h1b.mStream}</td>
                          <td>{h1b.bClgName}</td>
                          <td>{h1b.bYop}</td>
                          <td>{h1b.bStream}</td>
                          <td>{h1b.expDate}</td>
                          <td><div style={{ maxWidth: '300px', wordWrap: 'break-word', overflow: 'auto' }}>{h1b.message}</div></td>
                          <td>{h1b.referredBy}</td>

                          <td style={{ padding: 0 }}>
                            {/* <i className={`bi bi-cloud-arrow-down${h1b.status == "Received" ? "-fill text-primary" : ""}`} style={{ fontSize: "22px" }} onClick={() => H1Bstatus(h1b._id, "Received")}></i>
                            <i className={`mx-2 bi bi-check-circle${h1b.status == "Selected" ? "-fill text-success" : ""}`} style={{ fontSize: "22px" }} onClick={() => H1Bstatus(h1b._id, "Selected")}></i>
                            <i className={`bi bi-x-circle${h1b.status == "Rejected" ? "-fill text-danger" : ""}`} style={{ fontSize: "22px" }} onClick={() => H1Bstatus(h1b._id, "Rejected")}></i> */}
                            <select onChange={(e) => H1Bstatus(h1b._id, e.target.value)} >
                              <option value="Received" selected={h1b.status == "Received"}>Received</option>
                              <option value="Selected" selected={h1b.status == "Selected"}>Selected</option>
                              <option value="Rejected" selected={h1b.status == "Rejected"}>Rejected</option>
                            </select>
                          </td>

                          <td>
                            <a href={`${h1b.file}`} target="_blank">Link</a>
                          </td>
                          <td>
                            {h1b.passportFront && <a href={`${h1b.passportFront}`} target="_blank">Link</a>}
                          </td>
                          <td>
                            {h1b.passportBack && <a href={`${h1b.passportBack}`} target="_blank">Link</a>}
                          </td>
                          <td>
                            {h1b.createdAt && new Date(h1b.createdAt).toISOString().split('T')[0]}
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-dark"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <BiDotsVertical />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    handleDelete(h1b._id);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>

                          {/*Article Options*/}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="8">No H1B Application.</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
                {/* <Button
                  className="primary"
                  style={{
                    backgroundColor: "#5E72E4",
                    color: "white",
                    marginTop: "20px",
                  }}
                  onClick={contacts.length > 0 ? deleteAll : noDataToDelete}
                >
                  Delete All
                </Button> */}
              </Card>
            </div>
          </Row>
        </Container>
      ) : (
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Add New Contact</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        // href="/blogs"
                        onClick={(e) => {
                          handleToggle(e);
                          fetchH1B();
                        }}
                        size="sm"
                      >
                        Back to list
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form id="form">
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="name"
                              name="name"
                              value={newContact.name}
                              type="text"
                              onChange={(e) => {
                                setNewContact({
                                  ...newContact,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Email"
                              value={newContact.email}
                              name="email"
                              type="text"
                              onChange={(e) => {
                                setNewContact({
                                  ...newContact,
                                  email: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Phone Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="phone number"
                              value={newContact.phoneNumber}
                              name="phoneNumber"
                              type="text"
                              onChange={(e) => {
                                setNewContact({
                                  ...newContact,
                                  phoneNumber: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Message
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="message"
                              value={newContact.message}
                              name="message"
                              type="text"
                              onChange={(e) => {
                                setNewContact({
                                  ...newContact,
                                  message: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Upload File
                            </label>
                            <Input
                              className="form-control"
                              id="File"
                              type="file"
                              name="file"
                              onChange={(e) => {
                                const file_upload = e.target.files[0];

                                setFile_upload(file_upload);
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <div className="text-right">
                              <Button
                                color="primary"
                                onClick={(e) => {
                                  handleOnSubmit(e);
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default BlogIndex;
